const base = "https://backend.kelemm-digiclass.com";
const urls = {
  user: `${base}:1904/api/v1/users`,
  filter_users: `${base}:4040/filter-users`,
  sign_in: `${base}:2000/api/v1/auth/login`,
  signup: `${base}:2000/api/v1/auth/signup`,
  get_financial_info: `${base}:1098/transactions/instructor/withdrawable`,

  update_videos: `${base}:4040/administration/approve-video-update`,

  //Emailing
  email_api: `${base}:1100/send-email`,

  /*Statistics */
  total_students: `${base}:4040/administration/statistics/students`,
  total_courses: `${base}:4040/administration/statistics/courses`,
  top_5_instructors: `${base}:4040/administration/statistics/top-instructor`,
  top_5_students: `${base}:4040/administration/statistics/top-students`,
  top_5_courses: `${base}:4040/administration/statistics/top-courses`,
  user_expenditure: `${base}:4040/administration/statistics/student-courses`,
  top_assignments: `${base}:4040/administration/statistics/pending-assignments`,
  total_pending_instructor_time: `${base}:4040/administration/statistics/pending-instructor`,
  total_corporate_instructor_time: `${base}:4040/administration/statistics/corporate-instructor`,
  total_active_instructor_time: `${base}:4040/administration/statistics/active-instructor`,

  /**Site configuration */
  site_configurations: `${base}:4040/site-configuration`,
  theme: `${base}:3002/additional-instructor/theme`,

  // transactions
  all_payments: `${base}:4040/administration/all-transactions`,
  all_sales: `${base}:4040/administration/all-sales`,
  all_sales_filtered: `${base}:4040/administration/sales-filtered`,

  /**Instructor */
  all_students: `${base}:4040/administration/students`,

  /**Permissions */
  permission: `${base}:4040/administration/permission`,

  /**Courses */
  course: `${base}:1906/api/v1/courses`,
  students_for_paid_courses: `${base}:3002/additional-instructor/mystudents`,
  free_course_users: `${base}:3001/additional-course-service/free-course-users`,
  course_views: `${base}:3001/additional-course-service/course-view`,
  rating: `${base}:3002/additional-instructor/reviews/all`,
  category_name: `${base}:3002/additional-instructor/getcategoryname`,
  faq: `${base}:3031/faq`,
  youtube: `${base}:3030/youtube/videos`,
  section: `${base}:1906/api/v1/sections`,
  files: `${base}:3002/additional-instructor/files`,
  assignments: `${base}:3031/quiz/quiz-results`,
  assignments_by_course: `${base}:3031/quiz/quiz-results/all/students/instructor`,
  get_course_updates: `${base}:7001/update-course/get-course-update`,
  update_update: `${base}:7001/update-course/post-update`,
  categories: `${base}:1906/api/v1/categories`,
  delete_category: `${base}:4040/administration/delete-category`,
  update_category: `${base}:4040/administration/update-category`,
  quiz: `${base}:3031/quiz/question`,
  /**course updates */
  get_all_updates: `${base}:7001/update-course/get-all`,

  //Course sections
  get_vid_sec: `${base}:3001/additional-course-service/sectionvideo`,
  updateSection: `${base}:3001/additional-course-service/sectionname`,

  /**Quiz and certification */
  quiz_questions: `${base}:3031/quiz/question`,

  /**Notifications */
  notifications: `${base}:1099/notification/list`,
  post_notification: `${base}:1099/notification/send-notification`,
  update_notification: `${base}:1099/notification/seen`,
  notifications_read: `${base}:1099/notification/list-read`,

  /**user management */
  cart: `${base}:1911/api/v1/carts`,
  bulk_upload: `${base}:3002/additional-instructor/bulkupload`,
  bulk_upload_users: `${base}:3002/additional-instructor/bulk-upload-users`,
  single_upload: `${base}:3002/additional-instructor/single_upload`,

  /**Admin Roles */
  roles: `${base}:4040/administration/admin-roles`,

  uploads: `${base}:1905/api/v1/uploads`,

  /**Advertisement */
  post_ads: `${base}:1919/advertisement/post`,
  get_ads: `${base}:1919/advertisement/get`,
  update_ads: `${base}:1919/advertisement/update`,
  ads_interactions: `${base}:1919/advertisement/interactions`,

  /**
   * New Messaging
   */
  chat_list: `${base}:9110/simple-chat/chats`,
  conversations: `${base}:9110/simple-chat/messages`,
  send_conversation: `${base}:9110/simple-chat/messages`,
  unread_conversation: `${base}:9110/simple-chat/unread`,
  search_users: `${base}:9110/simple-chat/users`,

  /**Corporate organizations */
  update_corporate: `${base}:4040/administration/update-corporate-organization`,
  corporate: `${base}:1904/api/v1/corporates`,
  corporate_courses: `${base}:3001/additional-course-service/all-corporate-courses`,
  corporate_wallet: `${base}:1098/transactions/corporate/withdrawable`,

  /**financial management */
  withdrawals: `${base}:1098/transactions/instructor/withdraw`,
  all_transactions: `${base}:1098/transactions/all-transactions`,
  all_corporate_transactions: `${base}:1098/transactions/all-corporate-transactions`,
  transfer_money_to_user: `${base}:1098/transactions/transfer-money-to-user`,

  /**Course suspension messages */
  course_suspension_message: `${base}:3001/additional-course-service/course-suspension-message`,
};

export default urls;
