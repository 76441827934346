import { CircularProgress } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import ErrorHandler from "../components/hoc/general-error-handler";
import urls from "../core/base.url";
import baseService from "../core/baseServices";
import { StorageBox } from "../core/storage";
import GeneralContext from "./general-context";
import Swal from "sweetalert2";
import { displaySuccess, displayWarning } from "../components/alert";
import axios from "axios";
import FloatingCard from "./updoading_card";

export default function GeneralProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  const today: string = `1990-01-01 00:00:00/3100-01-01 23:59:59`;

  const [permissions, setPermissions] = useState<any>([]);
  const get_permissions = async () => {
    const role = StorageBox.retrieveUserData().additional_info?.role;

    try {
      const response: any = await baseService.get(urls.roles + `/${role}`);
      // console.log(response.data?.payload.permissions);
      setPermissions(response.data?.payload.permissions);
    } catch (error) {
      ErrorHandler(error);
    }
  };

  function checkPermission(input: string) {
    return new Promise(async (resolve, reject) => {
      const role = StorageBox.retrieveUserData().additional_info?.role;
      try {
        const response: any = await baseService.get(urls.roles + `/${role}`);
        const perms = response.data?.payload.permissions;
        // console.log(perms);
        setPermissions(perms);
        if (perms.includes(input)) {
          resolve(true);
        } else resolve(false);
      } catch (error) {
        resolve(false);
      }
    });
  }

  useEffect(() => {
    if (StorageBox.getAccessToken() !== null) {
      get_permissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StorageBox.getAccessToken()]);

  const [loadingTotalStats, setLoadingTotalStats] = useState<boolean>(true);
  const [totalStudents, setTotalStudents] = useState<number | React.ReactNode>(
    0
  );
  const [totalStudentsDateRange, setTotalStudentsDateRange] =
    useState<string>(today);
  const [totalInstructor, setTotalInstructor] = useState<
    number | React.ReactNode
  >(0);
  const [totalCourses, setTotalCourses] = useState<number | React.ReactNode>(0);

  useEffect(() => {
    const get_totals_users = async () => {
      try {
        const users_response: any = await baseService.get(
          urls.total_students + `/${totalStudentsDateRange}/user`
        );
        setTotalStudents(users_response.data?.total_students);
        // console.log(users_response.data);

        const instructor_response: any = await baseService.get(
          urls.total_students + `/${totalStudentsDateRange}/instructor`
        );
        setTotalInstructor(instructor_response.data?.total_students);

        const courses_response: any = await baseService.get(
          urls.total_courses + `/${totalStudentsDateRange}`
        );
        setTotalCourses(courses_response.data?.total_courses);
        //   console.log(courses_response.data);

        setLoadingTotalStats(false);
      } catch (error) {
        ErrorHandler(error);
      }
    };

    get_totals_users();
    setTotalStudents(<CircularProgress />);
    setTotalInstructor(<CircularProgress />);
    setTotalCourses(<CircularProgress />);
  }, [totalStudentsDateRange]);

  const [loadingPendingInstructors, setLoadingPendingInstructors] =
    useState<boolean>(true);
  const [pendingInstructors, setPendingInstructors] = useState<any>([]);

  const [loadingPublishedCourses, setLoadingPublishedCourses] =
    useState<boolean>(true);
  const [publishedCourses, setPublishedCourses] = useState<any>([]);
  const [publishedCoursesPage, setPublishedCoursesPage] = useState<number>(1);

  const [loadingPendingCourses, setLoadingPendingCourses] =
    useState<boolean>(true);
  const [pendingCourses, setPendingCourses] = useState<any>([]);
  const [pendingCoursesPage, setPendingCoursesPage] = useState<number>(1);

  const [loadingInReviewCourses, setLoadingInReviewCourses] =
    useState<boolean>(true);
  const [inReviewCourses, setInReviewCourses] = useState<any>([]);
  const [inReviewCoursesPage, setInReviewCoursesPage] = useState<number>(1);

  const [loadingSusCourses, setLoadingSusCourses] = useState<boolean>(true);
  const [susCourses, setSusCourses] = useState<any>([]);
  const [susCoursesPage, setSusCoursesPage] = useState<number>(1);

  const [total_notifications, setNotification] = useState<number>(0);
  useEffect(() => {
    const get_notifications = async () => {
      try {
        const response: any = await baseService.get(
          urls.notifications + `/admin?size=1`
        );
        const notifications = response.data.payload;
        if (notifications?.length > 0) {
          setNotification(response.data?.totalItems);
          Swal.fire({
            title: notifications?.[0].heading,
            text: notifications?.[0].message,
            toast: true,
            icon: "info",
            position: "bottom-end",
            timer: 6000,
            timerProgressBar: true,
            showConfirmButton: true,
            confirmButtonText: "Seen",
            confirmButtonColor: "#3085d6",
            showCancelButton: true,
          }).then(async (result: any) => {
            if (result.isConfirmed) {
              await baseService.put(urls.update_notification, {
                notif_id: notifications?.[0].id,
                user_id: StorageBox.retrieveUserData().user_id,
              });
              get_notifications();
            }
          });
        } else {
          setNotification(0);
        }
      } catch (error) {
        console.log(error);
      }
    };

    get_notifications();
  }, []);

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<string>("");
  const uploadVideoPersonalContext = async (
    selectedVideo: File | undefined,
    duration: string,
    selectSection: any,
    course_id: any
  ) => {
    try {
      if (
        selectedVideo === undefined ||
        duration === "" ||
        selectSection === null
      ) {
        displayWarning("please complete the form to proceed");
      } else if (uploading) {
        displayWarning(
          "Video upload in progress, please wait until upload is complete"
        );
      } else {
        setUploading(true); // console.log(data);
        let data = new FormData();
        data.append("title", selectSection.name);
        data.append("length", duration);
        data.append("section", selectSection.section_id);
        data.append("file", selectedVideo);

        const config = {
          method: "post",
          url: urls.course + `/${course_id}/videos`,
          headers: {
            Accept: "application/json",
            authorization: `Bearer ` + StorageBox.getAccessToken(),
          },
          data: data,
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // console.log(percentCompleted);
            setUploadProgress(
              `Upload progress: ${
                Number.isNaN(percentCompleted) ? 0 : percentCompleted
              }%`
            );
          },
        };
        await axios(config);
        Swal.fire({
          text: `Video uploaded successfully`,
          icon: "success",
          confirmButtonText: "Okay",
          toast: true,
          position: `bottom-right`,
          showConfirmButton: true,
        });
        setUploading(false);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const [totalPendingMessage, setTotalPendingMessage] = useState(0);
  useEffect(() => {
    const getMessage = async () => {
      try {
        const mess: any = await baseService.get(
          urls.unread_conversation +
            `/${StorageBox.retrieveUserData()?.user_id}`
        );
        setTotalPendingMessage(mess?.data?.payload);
      } catch (error) {}
    };

    getMessage();
  }, []);

  return (
    <Fragment>
      <GeneralContext.Provider
        value={{
          // background upload
          uploadVideoPersonalContext,
          /**Permissions management */
          permissions,
          setPermissions,
          get_permissions,
          checkPermission,

          loadingTotalStats,
          totalStudents,
          totalStudentsDateRange,
          setTotalStudentsDateRange,
          totalInstructor,
          totalCourses,

          /**loading pending instructors*/
          loadingPendingInstructors,
          setLoadingPendingInstructors,
          pendingInstructors,
          setPendingInstructors,

          /**Published courses */
          loadingPublishedCourses,
          setLoadingPublishedCourses,
          publishedCourses,
          setPublishedCourses,
          publishedCoursesPage,
          setPublishedCoursesPage,

          /**Pending courses */
          loadingPendingCourses,
          setLoadingPendingCourses,
          pendingCourses,
          setPendingCourses,
          pendingCoursesPage,
          setPendingCoursesPage,

          /**courses in review */
          loadingInReviewCourses,
          setLoadingInReviewCourses,
          inReviewCourses,
          setInReviewCourses,
          inReviewCoursesPage,
          setInReviewCoursesPage,

          /**Sus courses */
          loadingSusCourses,
          setLoadingSusCourses,
          susCourses,
          setSusCourses,
          susCoursesPage,
          setSusCoursesPage,

          total_notifications,

          totalPendingMessage,

          //video
          uploading,
        }}
      >
        {children}
        {uploading ? <FloatingCard uploadProgress={uploadProgress} /> : null}
      </GeneralContext.Provider>
    </Fragment>
  );
}
