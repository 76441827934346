import { LinearProgress } from "@mui/material";
import React, { Fragment } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateRoute from "./components/hoc/private-route";
import OutletWithProvider from "./context/out-let-with-provider";

const LoginPage = React.lazy(() => import("./pages/login"));
const OverviewPage = React.lazy(() => import("./pages/overview/overview"));
const CoursesPage = React.lazy(() => import("./pages/courses/courses"));
const StudentPage = React.lazy(() => import("./pages/students/students"));
const InstructorPage = React.lazy(
  () => import("./pages/instructor/instructor")
);
const InstructorDetailPage = React.lazy(
  () => import("./pages/instructor/instructor-detail")
);
const CourseDetailPage = React.lazy(
  () => import("./pages/course/course-detail")
);

const AdminPage = React.lazy(() => import("./pages/admin/administration"));
const AdvertisementPage = React.lazy(
  () => import("./pages/advertisement/advertisement")
);
const CreateAdvertisementPage = React.lazy(
  () => import("./pages/advertisement/create-advertisement")
);
const SiteManagementPage = React.lazy(
  () => import("./pages/site-management/site-management")
);
const CommunicationPage = React.lazy(
  () => import("./pages/communication/communication-page")
);
const OrganizationPage = React.lazy(
  () => import("./pages/organizations/org-page")
);
const FinancialPage = React.lazy(
  () => import("./pages/financial/financial-page")
);
const MyCoursesPage = React.lazy(
  () => import("./pages/my-courses/my-course-page")
);
const CourseUpdatePage = React.lazy(
  () => import("./pages/update-course/course-update-page")
);

const CourseUpdatesPage = React.lazy(
  () => import("./pages/course-updates/course-update-pages")
);

const NotificationsPage = React.lazy(
  () => import("./pages/notifications/notifications-page")
);

const AccountSettings = React.lazy(() => import("./pages/account"));

function App() {
  return (
    <Fragment>
      <React.Suspense fallback={<LinearProgress />}>
        <ProSidebarProvider>
          <Router>
            <Routes>
              <Route path="/" element={<LoginPage />} />

              {/* private routes */}
              <Route element={<PrivateRoute />}>
                <Route element={<OutletWithProvider />}>
                  <Route path="/overview" element={<OverviewPage />} />
                  <Route path="/courses" element={<CoursesPage />} />
                  <Route
                    path="/notifications"
                    element={<NotificationsPage />}
                  />
                  <Route
                    path="/course-update"
                    element={<CourseUpdatesPage />}
                  />
                  <Route
                    path="/course/:course_id"
                    element={<CourseDetailPage />}
                  />
                  <Route
                    path="/update-course/:course_id"
                    element={<CourseUpdatePage />}
                  />
                  <Route path="/students" element={<StudentPage />} />
                  <Route path="/instructors" element={<InstructorPage />} />
                  <Route
                    path="/instructor/:instructor_id"
                    element={<InstructorDetailPage />}
                  />
                  <Route path="/administration" element={<AdminPage />} />
                  <Route
                    path="/advertisement"
                    element={<AdvertisementPage />}
                  />
                  <Route
                    path="/post-advertisement"
                    element={<CreateAdvertisementPage />}
                  />
                  <Route
                    path="/post-advertisement/:ads_id"
                    element={<CreateAdvertisementPage />}
                  />
                  <Route
                    path="/site-management"
                    element={<SiteManagementPage />}
                  />

                  <Route
                    path="/communications"
                    element={<CommunicationPage />}
                  />

                  <Route path="/financial" element={<FinancialPage />} />
                  <Route path="/my-courses" element={<MyCoursesPage />} />

                  <Route path="/organizations" element={<OrganizationPage />} />
                  <Route path="/account" element={<AccountSettings />} />
                </Route>
              </Route>
            </Routes>
          </Router>
        </ProSidebarProvider>
      </React.Suspense>
    </Fragment>
  );
}

export default App;
