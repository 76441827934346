import { Outlet } from "react-router-dom";
import GeneralProvider from "./provider";

export default function OutletWithProvider() {
  return (
    <GeneralProvider>
      <Outlet />
    </GeneralProvider>
  );
}
