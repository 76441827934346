import { AxiosError, AxiosResponse } from "axios";
import { displayWarning } from "../alert";
import { StorageBox } from "../../core/storage";

export default function ErrorHandler(error: any) {
  if ((error as unknown as AxiosError).response as unknown as AxiosResponse) {
    console.log(error.response);
    if (error.response?.status === 400) {
      if (error.response?.data?.err?.details?.length >= 1) {
        return displayWarning(error.response?.data?.err?.details?.[0].message);
      }
    } else if (error.response?.status === 403) {
      return displayWarning(
        error.response?.data?.message ?? "Forbidden access"
      );
    } else if (error.response?.status === 401) {
      StorageBox.clearStorage();
    } else if (error.response.status === 409) {
      return displayWarning(error.response?.data?.message ?? "Duplicate entry");
    }
  } else {
    console.log("Unknown error", error);
  }
}
