import { Fullscreen } from "@mui/icons-material";
import { Card, Container } from "react-bootstrap";

const FloatingCard = (props: { uploadProgress: any }) => {
  const { uploadProgress } = props;
  return (
    <Card
      className="position-fixed bottom-0 start-0 mb-3 ms-3"
      style={{ width: "15rem", zIndex: 10000 }}
    >
      <Card.Body>
        <div className="d-flex justify-content-between">
          <Card.Title>Uploading video...</Card.Title>
          <Fullscreen style={{ cursor: "pointer" }} />
        </div>
        <Container className="text-center">
          {uploadProgress} <br />
          <small className="text-warning" style={{ fontSize: "10px" }}>
            Please do not reload the page.
          </small>
          <br />
          {/* <small style={{ fontSize: "10px" }}>{course_id}</small> */}
        </Container>
      </Card.Body>
    </Card>
  );
};

export default FloatingCard;
